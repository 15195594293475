import { POPOVER_TARGET } from "@blueprintjs/core/lib/esm/common/classes.js";
import styled, { css } from "styled-components";

import {
  HexAnchorButton,
  HexPopover,
  iconOnlyButtonStyles,
} from "../../../hex-components";
import { CategoryLabel } from "../../common/labels/CategoryLabel.js";
import { ProjectCardTypeIcon } from "../../common/project-cards/ProjectCardTypeIcon.js";

export const ProjectTitleAnchor = styled.a`
  min-width: 0;
  color: inherit;

  :hover {
    color: inherit;
  }
`;

export const TableCellText = styled.span`
  color: ${({ theme }) => theme.fontColor.MUTED};
`;

export const RowHeaderTableCellText = styled(TableCellText)<{
  $isUnknownHex: boolean;
}>`
  min-width: 0;
  color: ${({ $isUnknownHex, theme }) =>
    $isUnknownHex ? theme.fontColor.MUTED : theme.fontColor.DEFAULT};
  font-family: ${({ theme }) => theme.fontFamily.DEFAULT};
  font-size: ${({ theme }) => theme.fontSize.DEFAULT};
  font-weight: ${({ theme }) => theme.fontWeight.NORMAL};
  line-height: 1.3334;
`;

const opaqueBackgroundStyles = css`
  &:hover {
    /** !important is required to override the default button hover color */
    background-color: ${({ theme }) =>
      theme.NonTransparentHoverColor} !important;
  }
`;

export const ViewNotebookButton = styled(HexAnchorButton)`
  @media (max-width: ${({ theme }) => `${theme.mediaQuery.EXTRA_SMALL}px`}) {
    ${iconOnlyButtonStyles}
  }

  ${opaqueBackgroundStyles}
`;

export const MoreActionsProjectButton = styled(HexAnchorButton)`
  ${opaqueBackgroundStyles}
`;

export const MoreActionsButtonGroup = styled.div`
  display: flex;
  gap: 4px;
  height: 100%;

  justify-content: flex-end;
  padding-right: 2px;

  ${ViewNotebookButton} {
    align-self: center;
  }

  > .${POPOVER_TARGET} {
    display: flex;
    align-self: center;
  }
`;

export const MoreActionsMenuPopover = styled(HexPopover)``;

export const LoadingCellWrapper = styled.span`
  display: flex;
  flex: 1;
`;

export const FlexCategoryLabel = styled(CategoryLabel)`
  div:last-child {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const TableRowProjectTypeIcon = styled(ProjectCardTypeIcon)`
  display: inline-flex;
`;
