import { GridApi, ServerSideTransaction } from "@ag-grid-community/core";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { ButtonProps, Classes, Intent } from "@blueprintjs/core";
import { HexId, HexType, SpecialVersionType } from "@hex/common";
import React, { MouseEventHandler, useCallback, useMemo } from "react";
import styled from "styled-components";

import { IconOnlyButton } from "../../../../hex-components/HexButton.js";
import { useOnClickProps } from "../../../../hooks/useOnClickProps.js";
import { ORG_ID } from "../../../../orgs.js";
import { Routes } from "../../../../route/routes.js";
import { evtModKey } from "../../../../util/Keys.js";
import {
  ShowOnHover,
  ShowOnHoverContainer,
  StickyShowOnHover,
} from "../../../common/ShowOnHover.js";
import { StarIconCheckbox } from "../../../common/StarIconCheckbox.js";
import { useToaster } from "../../../common/Toasts.js";
import { HexRowOptionsMenuContents } from "../../../hex-list-2/hex-row/HexRowOptionsMenuContents.js";
import { useStarHex } from "../../../hex-list-2/hex-row/hooks/useStarHex.js";
import { TrashOptionsMenuContents } from "../../../hex-list-2/hex-row/TrashOptionsMenuContents.js";
import {
  getHexRoute,
  getHexSpecialVersionType,
} from "../../../hex-list-2/hex-row/utils.js";
import { EditIcon, MoreMenuIcon } from "../../../icons/CustomIcons.js";
import { ProjectsTableSafeOrUnknownHex_Hex_Fragment } from "../../ProjectsTable.generated.js";
import {
  MoreActionsButtonGroup,
  MoreActionsMenuPopover,
  ProjectTitleAnchor,
  RowHeaderTableCellText,
  TableRowProjectTypeIcon,
  ViewNotebookButton,
} from "../styled.js";
import {
  getRelativeUrlForExplore,
  getRelativeUrlForProject,
} from "../utils.js";

type FullLinkedNameData = ProjectsTableSafeOrUnknownHex_Hex_Fragment & {
  minimal?: false | undefined | null;
};

type MinimalLinkedNameData = Pick<
  ProjectsTableSafeOrUnknownHex_Hex_Fragment,
  | "__typename"
  | "hexType"
  | "id"
  | "title"
  | "lastPublishedVersion"
  | "canViewLogic"
  | "effectiveRole"
> & {
  minimal: true;
};

export type LinkedNameCellRendererData =
  | FullLinkedNameData
  | MinimalLinkedNameData;

export type LinkedNameWithActionsMenuRendererProps<DataType> =
  CustomCellRendererProps<DataType> & {
    anchorLinkOverride?: string;
    anchorTarget?: string;
  } & (
      | {
          mapDataToHex: (data: DataType) => FullLinkedNameData;
          minimal?: false | null | undefined;
        }
      | {
          mapDataToHex: (data: DataType) => MinimalLinkedNameData;
          minimal: true;
        }
    );

const genericMemo: <T>(component: T) => T = React.memo;
export const LinkedNameWithActionsMenuRenderer = genericMemo(
  function LinkedNameWithActionsMenuRenderer<D>(
    props: LinkedNameWithActionsMenuRendererProps<D>,
  ) {
    const {
      anchorLinkOverride,
      anchorTarget,
      api,
      context,
      data,
      mapDataToHex,
      minimal,
      node,
    } = props;
    const { canEditCollection, canSelect } = context ?? {
      canEditCollection: false,
      canSelect: false,
    };

    const handleOnRemoveFromCollectionSuccess = useCallback(() => {
      const transaction: ServerSideTransaction = {
        remove: [node.data],
      };

      api.applyServerSideTransaction(transaction);
    }, [node, api]);

    const handleOnStarChange = useCallback(
      (_, currentValue: boolean) => {
        if (data != null) {
          node.updateData({ ...data, starredByViewer: currentValue });
        }
      },
      [node, data],
    );

    if (data == null) {
      return null;
    }

    return (
      <LinkedNameWithActionsMenuRendererInner
        anchorLinkOverride={anchorLinkOverride}
        anchorTarget={anchorTarget}
        api={api}
        canEditCollection={canEditCollection}
        canSelect={canSelect}
        handleOnRemoveFromCollectionSuccess={
          handleOnRemoveFromCollectionSuccess
        }
        handleOnStarChange={handleOnStarChange}
        {...(minimal
          ? {
              minimal: true,
              hex: mapDataToHex(data),
            }
          : {
              minimal: false,
              hex: mapDataToHex(data),
            })}
      />
    );
  },
);

const LinkedNameWithActionsMenuRendererInner = React.memo<
  {
    canSelect: boolean;
    canEditCollection: boolean;
    handleOnRemoveFromCollectionSuccess: () => void;
    handleOnStarChange: (id: HexId, currentValue: boolean) => void;
    api: GridApi;
    anchorLinkOverride?: string;
    anchorTarget?: string;
  } & (
    | {
        minimal: false;
        hex: FullLinkedNameData;
      }
    | { minimal: true; hex: MinimalLinkedNameData }
  )
>(function LinkedNameWithActionsMenuRendererInner(props) {
  const {
    anchorLinkOverride,
    anchorTarget,
    api,
    canEditCollection,
    canSelect,
    handleOnRemoveFromCollectionSuccess,
    handleOnStarChange,
    hex,
    minimal,
  } = props;

  const title = hex.title;
  const isPublished = hex.lastPublishedVersion != null;
  const route = getHexRoute(hex, isPublished);
  const version = getHexSpecialVersionType(hex, isPublished);

  const primaryCardAnchorProps = useOnClickProps({
    replace: false,
    to:
      anchorLinkOverride ??
      (hex.hexType === HexType.EXPLORE
        ? getRelativeUrlForExplore(hex.id)
        : getRelativeUrlForProject(route, hex.id, version)),
    target: anchorTarget ?? undefined,
  });

  const handleOnContainerClick: MouseEventHandler<HTMLElement> = useCallback(
    (evt) => {
      if (api.getSelectedNodes().length > 0) {
        return;
      }

      if (evtModKey(evt)) {
        window.open(primaryCardAnchorProps.href, "_blank");
      } else {
        primaryCardAnchorProps.onClick(evt);
      }
    },
    [api, primaryCardAnchorProps],
  );

  const handleOnLinkClick: MouseEventHandler<HTMLElement> = useCallback(
    (evt) => {
      evt.stopPropagation();
      primaryCardAnchorProps.onClick(evt);
    },
    [primaryCardAnchorProps],
  );

  return (
    <LinkedNameContainer
      {...primaryCardAnchorProps}
      id={hex.id}
      onClick={handleOnContainerClick}
    >
      {!canSelect && (
        <TableRowProjectTypeIcon
          hexType={hex.hexType}
          isPublished={isPublished}
          isUnknownHex={false}
        />
      )}

      <ProjectTitleAnchor
        {...primaryCardAnchorProps}
        className={Classes.TEXT_OVERFLOW_ELLIPSIS}
        onClick={handleOnLinkClick}
      >
        <RowHeaderTableCellText $isUnknownHex={false}>
          {title}
        </RowHeaderTableCellText>
      </ProjectTitleAnchor>
      {hex.hexType !== HexType.EXPLORE && !minimal && (
        <ActionsMenu
          canEditCollection={canEditCollection}
          handleOnRemoveFromCollectionSuccess={
            handleOnRemoveFromCollectionSuccess
          }
          handleOnStarChange={handleOnStarChange}
          hex={hex}
        />
      )}
    </LinkedNameContainer>
  );
});

const ActionsMenu = React.memo<{
  canEditCollection: boolean;
  hex: FullLinkedNameData;
  handleOnRemoveFromCollectionSuccess: () => void;
  handleOnStarChange: (id: HexId, currentValue: boolean) => void;
}>(function ActionsMenu({
  canEditCollection,
  handleOnRemoveFromCollectionSuccess,
  handleOnStarChange,
  hex,
}) {
  const {
    archivedDate,
    canDelete,
    canEdit,
    canSetOwner,
    canShare,
    canViewLogic,
    collectionHexLink,
    currentDraft,
    effectiveRole,
    hexType,
    id,
    lastPublishedVersion,
    owner,
    projectLanguage,
    starredByViewer,
    trashDate,
    unarchivedDate,
  } = hex;
  const isComponent = hexType === HexType.COMPONENT;
  const isTrashed = trashDate != null;
  const rolesDeniedDueToAssetAccess =
    currentDraft?.denialReason?.__typename === "AssetAccess";
  const disabled = !effectiveRole || rolesDeniedDueToAssetAccess;
  const isPublished = lastPublishedVersion != null;
  const showDraftEditButton =
    !isComponent && isPublished && canEdit && !disabled;
  const canRemoveFromCollection =
    collectionHexLink != null ? canEditCollection : false;
  const route = getHexRoute(hex, isPublished);
  const version = getHexSpecialVersionType(hex, isPublished);
  const href = Routes.href(ORG_ID, true, route, {
    hexId: hex.id,
    version,
  });

  const editAnchorProps = useOnClickProps({
    replace: false,
    to: Routes.LOGIC.getUrl({
      hexId: id,
      version: SpecialVersionType.DRAFT,
    }),
  });

  const handleOnMenuClick: React.MouseEventHandler = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const content = useMemo(
    () =>
      isTrashed ? (
        <TrashOptionsMenuContents hexId={id} isComponent={isComponent} />
      ) : (
        <HexRowOptionsMenuContents
          archivedDate={archivedDate}
          canDelete={canDelete}
          canEdit={canEdit}
          canRemoveFromCollection={canRemoveFromCollection}
          canSetOwner={canSetOwner}
          canShare={canShare}
          canViewLogic={canViewLogic}
          currentDraft={currentDraft}
          hexType={hexType}
          href={href}
          id={id}
          lastPublishedVersion={lastPublishedVersion}
          owner={owner}
          projectLanguage={projectLanguage}
          unarchivedDate={unarchivedDate}
          onMenuClick={handleOnMenuClick}
          onSuccessRemoveTableRow={handleOnRemoveFromCollectionSuccess}
        />
      ),
    [
      isTrashed,
      id,
      isComponent,
      archivedDate,
      canDelete,
      canEdit,
      canRemoveFromCollection,
      canSetOwner,
      canShare,
      canViewLogic,
      currentDraft,
      hexType,
      href,
      lastPublishedVersion,
      owner,
      projectLanguage,
      unarchivedDate,
      handleOnMenuClick,
      handleOnRemoveFromCollectionSuccess,
    ],
  );

  const toaster = useToaster();

  const handleStarHexOnError = useCallback(
    (isStarred) => {
      toaster.show({
        message: isStarred
          ? `There was an error removing this ${isComponent ? "component" : "project"} from your favorites.`
          : `There was an error adding this ${isComponent ? "component" : "project"} to your favorites.`,
        intent: Intent.DANGER,
      });
    },
    [toaster, isComponent],
  );
  const handleStarIconCheckboxChange = useStarHex(
    id,
    handleStarHexOnError,
    handleOnStarChange,
  );

  const handleOnPopoverClick: MouseEventHandler = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const moreActionsMenuPopoverRenderTarget = useCallback(
    ({ ref, ...props }) => {
      return (
        <StickyShowOnHover
          $show={props.isOpen || undefined}
          css={`
            margin-left: auto;
          `}
          data-controller={id}
          onClick={handleOnPopoverClick}
        >
          <MoreActionsButtonGroup>
            {showDraftEditButton ? (
              <ViewNotebookButton
                {...editAnchorProps}
                icon={<EditIcon />}
                minimal={false}
                small={true}
                subtle={true}
                text="Edit"
              />
            ) : null}
            <MoreActionsButton {...props} elementRef={ref} />
          </MoreActionsButtonGroup>
        </StickyShowOnHover>
      );
    },
    [id, handleOnPopoverClick, showDraftEditButton, editAnchorProps],
  );

  return (
    <>
      <ShowOnHover
        $show={starredByViewer}
        css={`
          display: flex;
          padding-left: 2px;
          padding-right: 1px;
          padding-bottom: 2px;
        `}
        data-controller={id}
      >
        <StarIconCheckbox
          id={`star-checkbox-table-${id}`}
          starred={starredByViewer}
          onChange={handleStarIconCheckboxChange}
        />
      </ShowOnHover>
      <MoreActionsMenuPopover
        canEscapeKeyClose={true}
        content={content}
        position="right-top"
        renderTarget={moreActionsMenuPopoverRenderTarget}
      />
    </>
  );
});

const MoreActionsButton = React.memo<{
  onClick?: React.MouseEventHandler;
  elementRef?: ButtonProps["ref"];
}>(function MoreActionsButton({ elementRef, onClick }) {
  return (
    <IconOnlyButton
      ref={elementRef}
      icon={<MoreMenuIcon aria-hidden={true} svgOnly={true} />}
      minimal={true}
      small={true}
      text="View more actions"
      onClick={onClick}
    />
  );
});

const LinkedNameContainer = styled(ShowOnHoverContainer)`
  cursor: pointer;
  display: flex;
  align-items: center;
  && ${Classes.BUTTON} {
    background-color: ${({ theme }) => theme.NonTransparentHoverColor};
  }

  ${TableRowProjectTypeIcon} {
    padding-right: 8px;
  }
`;
